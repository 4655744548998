<template>
  <div>
    <div
      v-if="isMobileOpen"
      class="header-layout"
      @click="toggleMobileMenu"
    ></div>
    <header class="header" :class="{ header_fixed: isFixed }">
      <div class="container">
        <div class="header__inner">
          <router-link :to="$localePath({ name: 'Home' })" class="header-logo">
            <img
              class="d-none d-lg-block"
              :src="require('@/assets/img/logo.svg')"
              alt=""
            />
            <img
              class="d-lg-none"
              :src="require('@/assets/img/logo-mob.svg')"
              alt=""
            />
          </router-link>
          <div class="nav-wrapper">
            <div class="header-menu" :class="{ 'open-mobile': isMobileOpen }">
              <div class="hamburger-lines" @click="toggleMobileMenu">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
              </div>
              <ul class="menu-list">
                <li>
                  <router-link
                    :to="$localePath({ name: 'TradeTerms' })"
                    class="header-link"
                    active-class="active"
                  >
                    {{ $t("menu.terms") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Markets' })"
                    class="header-link"
                    active-class="active"
                  >
                    {{ $t("menu.markets") }}
                  </router-link>
                </li>
                <li
                  class="has-submenu"
                  :class="{ 'submenu-mobile_open': isSubmenuOpen }"
                >
                  <div class="submenu-mobile-link">
                    <router-link to="" class="header-link">
                      {{ $t("menu.trading") }}
                    </router-link>
                    <div class="submenu-btn" @click="toggleSubmenu()">
                      <span class="ic-arr-down"></span>
                    </div>
                  </div>
                  <ul class="submenu">
                    <li>
                      <router-link
                        :to="$localePath({ name: 'Terminal' })"
                        class="submenu__item"
                      >
                        {{ $t("subMenu.terminal") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="$localePath({ name: 'FundamentalsTrading' })"
                        class="submenu__item"
                      >
                        {{ $t("subMenu.fundament") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="$localePath({ name: 'FundamentalsAnalysis' })"
                        class="submenu__item"
                      >
                        {{ $t("subMenu.fAnalysis") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="$localePath({ name: 'TechnicalAnalysis' })"
                        class="submenu__item"
                      >
                        {{ $t("subMenu.tAnalysis") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="$localePath({ name: 'Faq' })"
                        class="submenu__item"
                      >
                        {{ $t("subMenu.faq") }}
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'About' })"
                    class="header-link"
                    active-class="active"
                  >
                    {{ $t("menu.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Contacts' })"
                    class="header-link"
                    active-class="active"
                  >
                    {{ $t("menu.contacts") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="header-buttons">
              <LanguageSwitcher />
              <router-link
                :to="$localePath({ name: 'Login' })"
                class="btn btn-sm btn-white-outline"
              >
                {{ $t("btn.login") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/content/LanguageSwitcher.vue";

export default {
  name: "AppHeader",
  components: { LanguageSwitcher },
  data: function () {
    return {
      isFixed: false,
      isMobileOpen: false,
      isSubmenuOpen: false,
    };
  },
  mounted() {
    const body = window.document.body;
    window.addEventListener("scroll", () => {
      if (window.scrollY > 120) {
        this.isFixed = true;
        body.classList.add("is-header-fixed");
      } else {
        this.isFixed = false;
        body.classList.remove("is-header-fixed");
      }
    });
  },
  watch: {
    $route() {
      this.isMobileOpen = false;
      this.isSubmenuOpen = false;
    },
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileOpen = !this.isMobileOpen;
    },
    toggleSubmenu() {
      this.isSubmenuOpen = !this.isSubmenuOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.header-layout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($dark, 0.2);
  z-index: 9;
}
.header {
  position: relative;
  background: $secondary-color;
  z-index: 10;

  &.header_fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .header__inner {
      padding: 20px 0;
    }
    .header-logo {
      height: 30px;
      img {
        height: 30px;
      }
    }
    .submenu {
      top: calc(100% + 27px);
    }
  }
}
.header__inner {
  padding: 40px 0;
  border-bottom: 1px solid rgba($white, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-logo {
  display: block;
  height: 60px;

  img {
    width: 100%;
    height: 60px;
  }
}
.nav-wrapper {
  display: flex;
  align-items: center;
  gap: 50px;
}
.header-menu {
  position: relative;
}
.menu-list {
  display: flex;
  align-items: center;
  gap: 60px;

  .has-submenu {
    position: relative;

    &:hover {
      .submenu {
        opacity: 1;
        transform: rotateX(0);
      }
    }
  }
}
.header-link {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  padding: 3px 0;
  color: #dbe2e5;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 2px;
  }

  &:hover,
  &.active {
    color: $white;
  }
}

.submenu-btn {
  display: none;
}
.submenu {
  min-width: 260px;
  opacity: 0;
  position: absolute;
  padding: 15px 40px;
  background: $white;
  top: calc(100% + 48px);
  left: -40px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  transition: all ease-in-out 0.3s;
  transform: rotateX(90deg);
  transform-origin: 0 0;
  border-radius: 0;

  li {
    padding: 14px 0;
    border-bottom: 1px solid $gray1;

    &:last-child {
      border-bottom: none;
    }
  }

  .submenu__item {
    color: $dark;
    font-size: 15px;
    line-height: 21px;

    &:hover {
      color: $primary-color_hover;
    }
  }
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 24px;

  .btn {
    width: 110px;
    padding: 9px 12px;
  }
}

.hamburger-lines {
  position: relative;
  display: none;
  height: 24px;
  width: 32px;
  cursor: pointer;
}

.hamburger-lines .line {
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  background: $primary-color;
  left: 0;
}

.hamburger-lines .line1 {
  top: 0;
  transform-origin: 0% 0%;
  transition: transform 0.3s ease-in-out;
}

.hamburger-lines .line2 {
  top: calc(12px - 1px);
  transition: transform 0.1s ease-in-out;
}

.hamburger-lines .line3 {
  bottom: 0;
  transform-origin: 0% 100%;
  transition: transform 0.3s ease-in-out;
}

.open-mobile .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.open-mobile .hamburger-lines .line2 {
  transform: scaleY(0);
}

.open-mobile .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

@include media-breakpoint-down(xl) {
  .menu-list {
    gap: 30px;
  }
}
@include media-breakpoint-down(xl) {
  .header-layout {
    display: block;
  }
  .nav-wrapper {
    flex-direction: row-reverse;
  }

  .header__inner {
    width: 100%;
    padding: 20px 0;
    justify-content: space-between;
  }
  .header-logo {
    height: 30px;
    img {
      height: 30px;
    }
  }

  .header-buttons {
    gap: 16px;
    .btn {
      width: auto;
      padding: 9px 10px;
    }
  }

  .hamburger-lines {
    display: block;
  }
  .menu-list {
    background: $secondary-color;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 78px;
    bottom: 0;
    width: 300px;
    padding: 30px;
    align-items: start;
    transition: all ease-in-out 0.3s;
    transform: translateX(110%);

    li {
      width: 100%;
    }
  }
  .open-mobile {
    .menu-list {
      transform: translateX(0);
    }
  }

  .submenu-mobile-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .submenu-btn {
    display: block;
    padding: 10px;
    font-size: 12px;
    color: $primary-color;
    line-height: 1;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    transform: rotate(0deg);
  }

  .header {
    .submenu {
      display: none;
      position: relative;
      top: inherit;
      left: inherit;
      opacity: 1;
      transform: none;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      padding: 5px 20px;

      li {
        padding: 10px 0;
        border-color: rgba($white, 0.1);
      }

      .submenu__item {
        color: $white;

        &:hover {
          color: $primary-color_hover;
        }
      }
    }
    .submenu-mobile_open {
      .submenu-btn {
        transform: rotate(180deg);
      }
      .submenu {
        display: block;
        top: inherit;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .nav-wrapper {
    gap: 20px;
  }
}
</style>

<template>
  <footer class="footer">
    <div data-aos="fade-up">
      <div class="container">
        <div class="footer__top">
          <div class="footer-logo">
            <router-link :to="$localePath({ name: 'Home' })">
              <img :src="require('@/assets/img/logo-footer.svg')" alt="" />
            </router-link>
          </div>
          <div class="footer-btn-block">
            <p>{{ $t("footerText.text") }}</p>
          </div>
        </div>
        <div class="footer__body">
          <div class="footer-nav">
            <ul class="footer-menu">
              <li>
                <router-link
                  :to="$localePath({ name: 'Markets' })"
                  class="main-menu__item"
                >
                  {{ $t("menu.markets") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'About' })"
                  class="main-menu__item"
                >
                  {{ $t("menu.about") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Contacts' })"
                  class="main-menu__item"
                >
                  {{ $t("menu.contacts") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'TradeTerms' })"
                  class="main-menu__item"
                >
                  {{ $t("menu.terms") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Faq' })"
                  class="main-menu__item"
                >
                  {{ $t("subMenu.faq") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Privacy' })"
                  class="main-menu__item"
                >
                  {{ $t("privacy") }}
                </router-link>
              </li>
            </ul>
            <ul class="footer-social" role="list">
              <li class="lsgs-e286f--footer-metabar-sociallink">
                <div class="lsgs-e286f--icon-link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="lsgs-e286f--action lsgs-e286f--action__interactive lsgs-e286f--action__no-overlay"
                    tabindex="0"
                    href="https://www.facebook.com/commerzbank/"
                  >
                    <svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      focusable="false"
                      role=""
                      aria-hidden="true"
                      id="lsgs-e286f--icon-link-m4h4q84y"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M4 2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Zm10.5 18v-6.4h2.1l.3-2.5h-2.4V9.5c0-.7.2-1.2 1.2-1.2H17V6.1c-.2 0-1-.1-1.8-.1a3 3 0 0 0-3.1 3.3v1.8H10v2.5h2.1V20Z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </li>
              <li class="lsgs-e286f--footer-metabar-sociallink">
                <div class="lsgs-e286f--icon-link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="lsgs-e286f--action lsgs-e286f--action__interactive lsgs-e286f--action__no-overlay"
                    tabindex="0"
                    href="https://www.instagram.com/commerzbank/"
                  >
                    <svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      focusable="false"
                      role=""
                      aria-hidden="true"
                      id="lsgs-e286f--icon-link-m4h4q84z"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M20 2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-.62 13.13a4.26 4.26 0 0 1-4.26 4.25H8.88a4.26 4.26 0 0 1-4.26-4.25V8.87a4.26 4.26 0 0 1 4.26-4.25h6.24a4.26 4.26 0 0 1 4.26 4.25v6.26z"
                      ></path>
                      <path
                        d="M15.12 5.88H8.88a3 3 0 0 0-3 3v6.26a3 3 0 0 0 3 3h6.24a3 3 0 0 0 3-3V8.87a3 3 0 0 0-3-2.99zM12 15.62a3.62 3.62 0 1 1 0-7.24 3.62 3.62 0 0 1 0 7.24zm4-6.74a.88.88 0 1 1 .88-.88.89.89 0 0 1-.88.88z"
                      ></path>
                      <path
                        d="M12 14.38a2.38 2.38 0 1 0 0-4.76 2.38 2.38 0 0 0 0 4.76z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </li>
              <li class="lsgs-e286f--footer-metabar-sociallink">
                <div class="lsgs-e286f--icon-link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="lsgs-e286f--action lsgs-e286f--action__interactive lsgs-e286f--action__no-overlay"
                    tabindex="0"
                    href="https://www.youtube.com/c/CommerzbankAG/"
                    ><svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      focusable="false"
                      role=""
                      aria-hidden="true"
                      id="lsgs-e286f--icon-link-m4h4q850"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M4 2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Zm5 14 8-4-8-4Z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </li>
              <li class="lsgs-e286f--footer-metabar-sociallink">
                <div class="lsgs-e286f--icon-link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="lsgs-e286f--action lsgs-e286f--action__interactive lsgs-e286f--action__no-overlay"
                    tabindex="0"
                    href="https://www.linkedin.com/company/commerzbank-ag/"
                  >
                    <svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      focusable="false"
                      role=""
                      aria-hidden="true"
                      id="lsgs-e286f--icon-link-m4h4q851"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M4 2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Zm11 7.3a2.38 2.38 0 0 0-2.4 1.3V9.4H9.9V17h2.7v-4.1c0-.2 0-.5.1-.6a1.32 1.32 0 0 1 1.3-.9c.9 0 1.4.7 1.4 1.7V17h2.7v-4.2c-.1-2.4-1.4-3.5-3.1-3.5ZM8.5 17V9.4H6.1V17ZM7.3 6a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 1 0 0-2.4Z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="lsgs-e286f--action lsgs-e286f--action__interactive lsgs-e286f--action__no-overlay"
                    tabindex="0"
                    href="https://www.xing.com/pages/commerzbank"
                    ><svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      focusable="false"
                      role=""
                      aria-hidden="true"
                      id="lsgs-e286f--icon-link-m4h4q852"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M4 2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Zm5.3 11.9 2-3.3L9.8 8H7.3l1.5 2.6-2 3.2h2.5Zm5.2-7.2L11 12.8l2.5 4.4h2.7l-2.5-4.4 3.5-6.1Z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  i18n: {
    messages: {
      en: {
        footerText: {
          title: "Risk Warnings:",
          text: "The bank at your side",
        },
        footerCopy: "© 2024, Соmmerzbаnk Invest. All rights reserved",
      },
      ru: {
        footerText: {
          title: "Предупреждения о рисках:",
          text: "Die Bank an Ihrer Seite",
        },
        footerCopy: "© 2024, Соmmerzbаnk Invest. Все права защищены",
      },
      fr: {
        footerText: {
          title: "Avertissements sur les risques:",
          text: "La banque à vos côtés",
        },
        footerCopy: "© 2024, Соmmerzbаnk Invest. Tous droits réservés",
      },
      de: {
        footerText: {
          title: "Risikowarnungen:",
          text: "Die Bank an Ihrer Seite",
        },
        footerCopy: "© 2024, Соmmerzbаnk Invest. Alle Rechte vorbehalten",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  background: $secondary-color;
  color: $white;
}
.footer_bottom {
  background: $secondary-color-light;
}
.footer__top {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
}
.footer-logo {
  img {
    max-width: 200px;
  }
}
.footer-btn-block {
  display: flex;
  align-items: center;
  gap: 100px;
  font-size: 18px;
  font-weight: 600;
  @include media-breakpoint-down(lg) {
    gap: 50px;
  }
  @include media-breakpoint-down(lg) {
    gap: 30px;
    flex-direction: column;
    justify-content: center;
  }
}
.footer-nav {
  padding: 30px 0;
  border-top: 1px solid rgba($white, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

.footer-main-menu {
  border-bottom: 1px solid rgba($white, 0.1);
  padding-bottom: 30px;
}

.main-menu__item {
  font-size: 13px;
  font-weight: 700;
  color: #dbe2e5;

  &:hover,
  &.active {
    color: $white;
  }
}
.footer__bottom {
  border-top: 1px solid rgba($white, 0.1);
  padding: 30px 0 40px;
  font-size: 12px;
  color: rgba($white, 0.6);
}

.footer-menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.footer-social {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  a {
    color: rgba($white, 0.76);

    &:hover {
      color: $white;
    }
  }
}

@include media-breakpoint-down(md) {
  .footer-nav {
    justify-content: center;
  }
  .footer-menu {
    gap: 20px;
    justify-content: center;
  }
}
</style>
